@import '../../style/abstract/variables';

@import '../../style/abstract/media';
@import '../../style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';
@import '../../style/abstract/placeholder';

.MyAccountCustomerPopup {
    .Button {
        --button-height: 55px;
        --button-hover-height: 55px;
    }
    .Form {
        .FieldForm-Fields {
            margin-bottom: 32px;
        }
    }
}
